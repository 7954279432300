/* CustomModal.css */
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.bgCOD{
  background-image: linear-gradient(rgb(0 0 0 / 60%), rgb(0 0 0 / 60%)), url(https://tmadocumnetbucket.s3.ap-northeast-1.amazonaws.com/ALevelTshirts/Carasal/Cash%20On%20Delivery%20Alert%20Post%20PNG.png) !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-modal-content {
  border: 2px solid #8e8e3e;
  padding: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
}

.custom-modal-content p{
  font-size: 20px;
  color: #8e8e3e;
}

.custom-modal button {
  margin: 0 10px;
  margin-top: 20px;  
  border: 2px solid ;
  width: 250px;
}
.custom-modal button.yes {
  background-color: green;  
  color: #fff;
  border-color: green;
}
.custom-modal button.no {
  background-color: red;  
  color: #fff;
  border-color: red;
}

@media(max-width:900px){
  .custom-modal{
    padding: 10px;
  }
}

@media(min-width:900px){
  .custom-modal-content {
    padding: 135px 50px;
  }
}