
  
  .wheel {
    width: 500px;
    height: 500px;    
    transition: transform 0s ease-out;
    border: 10px solid #fff; 
    border-radius: 50%;
    box-sizing: border-box;
    
  }
  .arrow {
    transform: rotate(180deg);
}
  .spinner-container{
    display: flex;
    background: #191919;
    justify-content: center;
  }
  
  .button-container {
    margin-top: 20px;
  }
  .ar-image {
    position: absolute; 
    width: 100%; 
    top: -10px;
    text-align: center; 
    z-index: 1000;
}

.ar-image img {
    display: block; 
    margin: 0 auto; 
}


  .ar-image div{
    width: 100px;
    margin-top: -20px;
    height: 70px;
    background-color: red;
    clip-path: polygon(51% 23%, 70% 0, 60% 80%, 70% 81%, 50% 100%, 30% 80%, 40% 80%, 31% 0);
  }
  
  .btn.start {
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 2px solid red;
    width: 200px;
    font-weight: "bold";
    color: #fff;
    transition: 0.5s;
    background: red;
    height: 50px;
  }
  .btn.claim {
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 2px solid red;
    width: 150px;
    font-weight: "bold";
    color: #fff;
    transition: 0.5s;
    margin-top: 10px;
    background: red;
    height: 40px;
    font-size: 12px;
  }

  .btn.share {
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 2px solid #1A5319;
    width: 350px;
    font-weight: "bold";
    color: #fff;
    margin-left: 5px;
    transition: 0.5s;
    background: #1A5319;
    height: 60px;
  }
  
  .btn:hover,
  .btn:active {
    color: #fff!important;
    background-color: #000;
    text-decoration: none;
    border: 2px solid #8e8e3e!important;
  }
  
  @keyframes shining {
    0% {
      opacity: 0.8;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.3), 0 0 20px rgba(255, 255, 255, 0.3), 0 0 30px rgba(255, 255, 255, 0.3), 0 0 40px rgba(255, 255, 255, 0.3);
    }
    50% {
      opacity: 1;
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5), 0 0 40px rgba(255, 255, 255, 0.5), 0 0 50px rgba(255, 255, 255, 0.5);
    }
    100% {
      opacity: 0.8;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.3), 0 0 20px rgba(255, 255, 255, 0.3), 0 0 30px rgba(255, 255, 255, 0.3), 0 0 40px rgba(255, 255, 255, 0.3);
    }
  }
  
  .shining {
    animation: shining 2s infinite;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  @media(max-width: 900px){
    .wheel {
      width: 300px;
    height: 300px;  
    }
    .btn.share,.btn.start{
      width: 280px;
      font-size: 12px;
    }
  }