.table-responsive {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
  }
  
  .table th,
  .table td {
    white-space: nowrap;
  }
  
  @media (max-width: 767.98px) {
    .table thead {
      display: block;
    }
    
    .table tbody {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  
    .table tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  
    .table tbody td {
      display: block;
      text-align: right;
    }
  
    .table tbody td:before {
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: bold;
    }
  
    .table th,
    .table td {
      white-space: normal;
    }
  }

  
  